<style lang="less" >

.ugo-item-user{
  width:116px;height:38px;padding:0 10px;background:#fff;
  cursor: pointer;

}

.ugo-item-user:hover{
  background:gold;
  transition: all 0.3s;
}

.ugo-item-user-selected{
  background:gold;
  color:#fff;
}

.ivu-select-dropdown{
  max-height:400px;
}
</style>
<template>
  <FormItem :label="label || ''" :prop="fkey">
    <BaseUserSelectGrouped 
      :value="value"
      @input="handleInput"
      v-bind="lConfig"
      :option="{
        multiple:lConfig.multiple
      }"
    />
  </FormItem>
</template>

<script>
export default {
  cname:"用户选择",
  icon:'xialaliebiao',
  corder:32,
  props:['fkey','label','config','value','editable'],
  properties:[ {
            label:'多选',
            key:'multiple',
            control:"Check"
        },],
  computed:{
    lConfig(){
      return this.config || {}
    }
  },
  methods:{
    handleInput(e){
      console.log('user-select:',e)
      this.$emit('input',e)
    }
  }
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>