import {API} from '@/vue-plugins/api'
const forms = {}
const state = {
    form:{},
    globalIndex:0
}

const getters = {
    form(state){
        return state.form
    },
    containers(state){
        return ['UgoRow','UgoSection','UgoTabs']
    },

    
}

const actions = {
    get({commit},model){
        if(forms[model]){
            commit('save',form)
            return Promise.resolve(form)
        }else{
            return API.get(`models/${model}?view=edit`).then(res=>{
                let form = res.data.data
                form.model = model
                commit('save',form)
            })
        }
       
    },
    getByID({commit},model){
        return API.get(`forms/${id}`).then(res=>{
            let form = res.data.data
            commit('save',form)
        })
    },
    createGlobaID({commit,state}){
        commit('add_gid')
        return Promise.resolve(state.globalIndex)
    }
}

const mutations = {
    save(state,form){
        forms[form.model] = form
        state.form = form
    },
    add_gid(state){
        state.globalIndex = state.globalIndex++
    }
}


export default {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}