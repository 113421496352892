<template>
	<div class="container l-cnotices">

		<div class="flex-wrap flex-between" style="width:100%;height:30px;font-weight:bold;">
      <div class="flex-wrap"><BaseIcon icon="md-apps" style="margin-right:5px" /> 
	  <template v-for="(p,i) in panels">
		<div class="flex-wrap" :key="p.key">
		<Badge :dot="p.new"  ><a @click="selected = p.key;getData(true)" :style="`color:${selected==p.key?'var(--primary)':'var(--subtext2)'}`">{{ p.name }}</a></Badge>
		<Divider type="vertical" v-if="i !== panels.length" />
		</div>
	  </template>
	</div>
	<a @click="RouteToApp"><BaseIcon icon="ios-more" :size="20" /></a>
    </div>
		<div style="
	border-radius: 2px;
	height:calc(100% - 30px);
	">
		<BaseLoading v-if="loading"> </BaseLoading>
    <BaseEmpty v-else-if="items.length == 0" msg="这个板块还没有内容上传" />
		<template v-else>
			<template v-for="(item,i) in items">
				<div
					:key="'notice_'+i+'_' + item.id"
					class="flex-wrap flex-between flex-line notice-item"
					:style="`${item.id?'':'height:35px;border:none;'};height:37px;`"
				
					@click="handleOpen(item)"
				>
					<div class="flex-wrap title" style="overflow:hidden;">
						<span style="padding:1px 5px;background:var(--border);border-radius:1px;border:1px solid var(--border);font-size:12px;margin-right:8px;color:var(--text3);white-space:nowrap;flex-shrink:0;" v-if="item.dep">{{ item.dep}}</span>
						<span style="padding:1px 5px;background:var(--border);border-radius:1px;border:1px solid var(--border);font-size:12px;margin-right:8px;color:var(--text3);white-space:nowrap;flex-shrink:0;" v-if="item.code">{{ item.code}}</span>

						 <div style="flex-grow:1;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;" :title="item.name">{{ item.name }}</div>
					</div>
					
					<div v-if="item.date || item.created_at" style="flex-shirnk:0;font-size:14px;color:var(--success);margin-left:10px;">
						{{ moment(item.date || item.created_at).format("MM/DD") }}
					</div>
				</div>
			</template>
		</template>
		</div>
	</div>
</template>
  
  <script>
export default {
	data() {
		return {
			loading: false,
			items: [],
			selected:'books',
		};
	},
	computed:{
		panels(){
			return [{
          key:'books',
          name:"高专读书会"
        },{
          key:'recommend',
          name:"推荐文章"
        },{
          key:'exp',
          name:'经验分享'
        }]
		}
	},
	created() {
		this.getData()
		if(this.data.key){
				
			this.$bus.$on('article-'+this.data.key,()=>{
				
				this.getData()
			})
			
		}
	},
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	methods: {
		getData(forced=false) {
			if (forced || this.items.length == 0) {
				setTimeout(() => {
					this.loading = true;
					this.$api
						.get(`articles?t=${this.selected || "notice"}`)
						.then((res) => {
							let items = res.data.data;
							if(items.length < 6){
								for(let i=0;i<6-items.length;i++){
									items.push({})
								}
							}
							this.items = items
						})
						.finally(() => {
							this.loading = false;
						});
				}, 500);
			}
		},
		RouteToApp(){
			let base_url = "/core/articles-admin"
			let url = base_url + "?t="+(this.selected|| 'notice')
			this.$store.commit("session/push", {
				name: '文章库',
				icon: "md-bookmarks",
				url,
				base_url,
			});
			this.RouteTo(url, false);
		},
		handleOpen(item) {
			if(!item || !item.id)
				return
			let url = "/core/articles/" + item.id;
			this.$store.commit("session/push", {
				id: item.id,
				name: item.name,
				icon: "md-document",
				url,
				base_url:url,
			});
			this.RouteTo(url, false);
		},
	},
};
</script>
  
  <style lang="less">
.l-cnotices {
	overflow: hidden;
	position: relative;

}

.notice-item{
	padding: 7px;
            width:100%;
						font-size: 14px;
						border-bottom: 1px dashed var(--border);
						cursor: pointer;

						.title{
							color:var(--text2)
						}
}
.notice-item:hover{
	filter:brightness(1.1);
	transition: all 0.5s;
	border-color:var(--primary);

	.title{
		color:var(--primary);
	}
}
</style>