<style lang="less">
.pd-prop{
  display: flex;
  height:25px;
  border-bottom:1px solid var(--border);
  font-family: 'PingFang-SC';
  
  .pd-label{
    display: flex;
    align-items: center;
    justify-content: center;
    width:80px;
    font-size:12px;
    border-right:1px solid var(--border);
  }
   .pd-value{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    font-size:12px;
    color:var(--primary);
    
    width:calc(100% - 80px);
  }

  .pd-value:hover{
    transition: all 0.3s;
    filter:brightness(1.1);
    cursor: text;
  }
}

.pm-embed-control{
  position: relative;
  height:100% !important;
  width:100%;
  overflow: hidden;
  background:rgb(239, 239, 201);

  .ivu-dropdown{
    display:block !important;
    height:100%;
  }
  .ivu-dropdown-rel{
    height:100%;
  }

  .value-wrap{
    padding-left:11px;background:rgb(239, 239, 201);height:100%;font-size:13px;width:100%;color:rgb(163, 163, 163);line-height:28px
  }
  
}
</style>
<template>
  <div style="overflow:hidden;width:100%;"> 
    <div style="overflow:hidden">
       <template v-for="(p,i) in props">
      <div class="pd-prop" :style="p.control=='DetailSelect'?'height:60px':''" :key="'pi'+i+p.key">
        <div class="pd-label">{{p.label}}</div>
        <div class="pd-value">
          <template v-if="p.control">
            <component 
              :is="`Pe${p.control || 'Text'}`"
              :option="p.option"
              :editable="!disabledAll && !p.disabled && !disabled[p.key]" 
              :value="value[p.key]"
              :db="value"
              @input="handleChange(p.key,$event)" @save="$emit('save')" 
              :env="env" 
            />
          </template>
          <template v-else>
            {{value[p.key] || ''}}
          </template>
          </div>
      </div>
      </template>
    </div>
  </div>
</template>

<script>
  import PeText from '@/components/pd/Controls/Text'
  import PeColumnDesign from '@/components/pd/Controls/ColumnDesign'
  import PeCheck from '@/components/pd/Controls/Check'
  import PeSelectOption from '@/components/pd/Controls/SelectOption'
  import PeState from '@/components/pd/Controls/State'
  import PeSelect from '@/components/pd/Controls/Select'
  import PeFormSelect from '@/components/pd/Controls/FormSelect'
  import PeTutorialSelect from '@/components/pd/Controls/TutorialSelect'
  import PeFlowSelect from '@/components/pd/Controls/FlowSelect'
  import PeTaskSelect from '@/components/pd/Controls/TaskSelect'
  import PeTextarea from '@/components/pd/Controls/Textarea'
  import PeFiles from '@/components/pd/Controls/Files'
  import PeDate from '@/components/pd/Controls/Date'
  import PeUserSelect from '@/components/pd/Controls/UserSelect'
  import PeDetailSelect from '@/components/pd/Controls/DetailSelect'
  import PeFieldSelect from '@/components/pd/Controls/FieldSelect'
  import PePermissionSelect from '@/components/pd/Controls/PermissionSelect'
  import PeMText from '@/components/pd/Controls/MText'
  import PeFormPermission from '../pd/Controls/FormPermissions.vue'
  
  export default {
    components:{
      PeText,PeSelect,PeState,PeFormSelect,PeTaskSelect,PeTutorialSelect,PeFlowSelect,PeCheck,PeSelectOption,PeColumnDesign,PeTextarea,PeFiles,PeDate,PeUserSelect,PeDetailSelect,PeFieldSelect,PePermissionSelect,PeMText,PeFormPermission
    },
    props:{
      value:{
        type:Object,
        default:()=>({})
      },
      attrs:{
        type:Array,
        default:()=>({})
      },
      disabled:{
        type:Object,
        default:()=>({})
      },
      disabledAll:{
        type:Boolean
      },
      env:{
        type:Object,
        default:()=>({})
      }
    },
    watch:{
      value:{
        handler(v){
          this.$forceUpdate()
        }
      }
    },
    methods:{
handleChange(key,v){
  this.$set(this.value,key,v)
  this.$emit('input',this.value)
  this.$emit('on-change',{
    key,
    value:v
  })
  
}
    },
    computed:{
      props(){ 
        if(Array.isArray(this.attrs) && this.attrs.length > 0)
          return this.attrs


        if(!this.value || Object.keys(this.value).length == 0)
          return []
        return Object.keys(this.value).map(key=>{
                   
             return {
            key,
            label:key,
            control:'Text',
            option:{},
            editable:true
          }
          
         
        })
      }
    }
  }
</script>

