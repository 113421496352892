<template>
    <div style="width:100%;position:relative;min-height:100px;margin-bottom:10px;">
    <BaseTabs 
        :items="tabs"
        :editable="design"
        :actived="activeTab"
        @on-click="handleSelect" 
        @on-add="handleAddTab"
        @on-delete="handleRemoveTab"
    />
    
    <div :title="label" :desc="config.desc" :icon="config.icon" style="width:100%;border:1px solid var(--border);">
          <draggable
            tag="div"
            v-model="activedPage.fields"
            group="control"
            :animation="500"
            @input="emitChange"
            :disabled="!design"
            filter=".drag-tip"
            style="background:#ffffffee;min-height:150px;width:100%;display: block;border:1px dashed #aaa;position:relative;"
            :style="`${design?'':'border:none;'}`"
          >
          <template v-if="design">
            <div class="flex-wrap flex-center" style="height:150px;width:100%;color:var(--primary)" v-if="localList.length == 0">
              拖拽至此处
            </div>
            <template v-else>
              
              <template v-for="(c,i) in activedPage.fields">
                <div class="form-item flex-wrap flex-between" :class="selected && selected.id === c.id ?'form-item-selected':''"  :key="c.id" 
                @click.stop="handleClick(c)" style="border:1px solid var(--primary);margin:12px;position:relative;overflow:hidden;padding:5px;">
                <div class="l-mask" v-if="!isContainer(c.control) && design"></div>
                <div class="l-remove-btn" @click.stop="localList.splice(i,1);emitChange()"><Icon type="md-close" /></div>
                  <component 
                  :is="c.control"
                  :required="c.required?true:false"
                  :selected="selected"
                  :label="c.name"
                  :id="c.id"
                  :fields="c.fields"
                  :fkey="c.key"
                  :config="c.config"
                  :editable="editable"
                 
                  :design="design"
                  @select="$emit('select', $event)"
                  @change="$set(c,'fields',$event);emitChange()"
                  />
                </div>
                
              </template>
                </template>
            </template>
            <template v-else>
              <template v-for="c in activedPage.fields">
                <div class="form-item flex-wrap flex-between"  :key="c.id" 
                @click.stop="handleClick(c)" 
                 v-if="acl[c.fullkey]!=2"
                style="position:relative;overflow:hidden;padding:5px;">
                  <component 
                    :is="c.control" 
                    :label="c.name"
                    :required="c.required?true:false"
                    :fkey="c.key"
                    :fields="c.fields"
                    :acl="acl"
                    :id="c.id"
                     :model="model"
                    :config="c.config" 
                    :editable="editable && (!c.config || !c.config.disabled) && !acl[c.fullkey]" 
                    :value="isContainer(c.control)?value:value[c.key]"
                     @input="handleEmitInput(c,$event)"
                  />
                  
                </div>
                
              </template>
            </template>
            </draggable>
         
     
    </div>
    </div>
  </template>
  
  <script>
  import BaseTabs from '@/components/app/tabs2'
  import draggable from 'vuedraggable';
    import {cloneDeep} from 'lodash'
    export default {
      cname:"标签页(容器)",
      icon:"biaoge",
      corder:80,
      components:{
        draggable,BaseTabs
      },
      data(){
        return {
          localList:cloneDeep(this.fields) || [],
          activeTab:''
        }
      },
      watch:{
        fields:{
          handler(v){
            this.localList = cloneDeep(v) || []
          },
          deep:true
        }
      },
      computed:{
        activedPage(){
            return this.localList.find(v=>v.key == this.activeTab) || {}
        },
        tabs(){
            return this.localList.map(v=>({key:v.key,name:v.name}))
        }
      },
      mounted(){
        this.activeTab = this.localList[0].key
      },
      props:{
        design:{
          type:Boolean,
          default:false
        },
        selected:{
          type:Object
        },
        fields:{
          type:Array,
          default:()=>([])
        },
        acl:{
        type:Object,
        default:()=>({})
      },
        label:{
          type:String
        },  
        model:{
          type:Object,
          default:()=>({})
        },
        value: {
        type: Object,
        default: () => ({})
      },
        old:{
          type:Object,
          default:()=>({})
        },
        editable:{
          type:Boolean
        },
        config:{
          type:Object,
          default:()=>({})
        },
        acl:{
          type:Object,
          default:()=>({})
        }
  
      },
      properties:[
        {
          label:'描述',
          key:'desc',
          control:'Text'
        }
  
      ],
      methods:{
        handleClick(c){
        this.$emit('select',{item:c,ctx:this})
      },
        handleEmitInput(c,e){
          if(this.isContainer(c.control)){
            this.$emit('input',e)
          }else{
            this.$emit('input',{key:c.key,value:e})
          }
        },
        handleEnd(e){
          console.log('end:',e)
        },
        handleAddTab(){
            let newTab = this.initTab(Date.now())
            this.localList.push(newTab)
            this.emitChange()
        },
        handleSelect(e){
            this.activeTab = e;
            this.$emit('select',{item:this.activedPage,ctx:this})
        },
        handleRemoveTab(t){
            this.Confirm(`确定移除标签页<span style="color:var(--primary);margin:0 3px">{{t.name}}</span>及其内所有元素)?`,()=>{
                let index =this.localList.find(v=>v.key == t.key)
                if(index !== -1)
                    this.localList.splice(index,1)
            })
        },
        initTab(i){
          return {
            id:this.fkey + 'tab'+i,
            key:this.fkey + 'tab'+i,
            name:'新标签',
            control:'UgoTab',
            fields:[]
          }
        },
        emitChange(){
          this.$emit('change',cloneDeep(this.localList))
        },
        isContainer(control){
        return this.$store.getters['forms/containers'].includes(control)
      },
      },
      
      initModel(obj){
          obj.config = {
            cols:2
          }
          obj.fields = [{
            key:obj.id + 'tab0',
            name:"标签一",
            fields:[]
          }]
          return obj
        }
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>