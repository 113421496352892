<template>
    <FormItem :label="label">
      <BaseMarkdownEditor 
        type="textarea"
        v-model="_value"
        :option="_config"
        :preview="!editable"
        :placeholder="_config.placeholder || '请输入'"
        style="width:100%;height:100%"
         />
    </FormItem>
  </template>
  
  <script>
    export default {
      cname:"富文本输入",
      corder:20,
      icon:'changwenbenxiangsi',
          props:['value','design','label','required','config','editable'],
      computed:{
        _value:{
          get(){
            return this.value
          },
          set(v){
            this.$emit('input',v)
          }
        },
        _config(){
          return this.config || {}
        }
      },
      properties:[
        {
          label:'字数限制',
          key:'maxlen',
          control:'Text',
        },
        {
          label:'宽度',
          key:'width',
          control:'Text'
        },
        {
          label:'行数',
          key:'rows',
          control:'Text',
          option:{
            number:true
          }
        }
  
      ]
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>