// BASE SETTING to VUE
import bus from './bus'
// Imports
import Vue from 'vue'
import api from './api'
import moment from 'moment'
import UTIL from '@/utils/index'
import iView from 'view-design'
import lazyload from '../directives/lazyload'
Vue.use(iView)
import Contextmenu from "vue-contextmenujs"
Vue.use(Contextmenu);

Vue.directive('lazy-load',lazyload)

// markdown
import VueMarkdownEditor from '@kangc/v-md-editor';
import githubTheme from '@kangc/v-md-editor/lib/theme/github';
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css';
VueMarkdownEditor.use(createHighlightLinesPlugin());
// highlightjs 核心代码
import hljs from 'highlight.js/lib/core';
// 按需引入语言包
import json from 'highlight.js/lib/languages/json';
import javascript from 'highlight.js/lib/languages/javascript.js'
import py from 'highlight.js/lib/languages/python'
hljs.registerLanguage('json', json);
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', py);


import pick from 'lodash/pick'

VueMarkdownEditor.use(githubTheme, {
  Hljs: hljs,
});
VueMarkdownEditor.use(createLineNumbertPlugin());
Vue.use(VueMarkdownEditor);

// Setup PuzzleVerification plugins
Vue.prototype.moment = moment
Vue.prototype.Download = function (url) {
  let a = document.createElement("a")
  a.href = url
  a.download = "download"
  a.target = "blank"
  document.body.appendChild(a)
  a.click()
  a.remove()
}

Vue.prototype.$close = function(){
  
  let path = this.$route.path
  let threads = this.$store.getters["session/threads"]
  let index = threads.findIndex(v=>path && path.includes(v.base_url))
  let prev = '/dashboard'
  console.log(index,threads)
  if(index == -1){
    if(threads.length != 0){
      prev = threads[threads.length - 1].path || threads[threads.length - 1].url
    }
  }else if(index > 0){ 
    prev = threads[index-1].url || threads[index-1].path
    this.$store.commit("session/remove",index)
  }
  console.log(prev)
  this.RouteTo(prev)
}


Vue.prototype.$util = UTIL

Vue.prototype.$Notice.config({
  top: 50,
  duration: 3
})

Vue.prototype.$bus = bus
Vue.use(api)

const debouncePool = {}
Vue.prototype.debounce = (func,delayTime=500,options)=>{
  if(!func)
      return
  let {timeStamp,timer} = debouncePool[func] || {}
  if(timeStamp && moment(timeStamp).isAfter(moment().subtract(300,'seconds'))){
    return
  }else{
    clearTimeout(timer)
    delete debouncePool[func]
  }
  timer = setTimeout(() => {
    func(options)
    delete debouncePool[timer]
  }, delayTime);
  debouncePool[func] = {
    timer,
    timeStamp:moment().format()
  }
}


// Predefined Some Easy Prompt Methods based on iview
Vue.prototype.Confirm = function (content, cb, cb2, option = {}) {
  this.$Modal.confirm({
    title: '操作确认',
    content,
    onOk: cb,
    onCancel: cb2 || (()=>{}),
    ...option
  })
}

Vue.prototype.Error = function (obj){
  this.$Modal.error({
    title:'错误',
    content:typeof obj == 'object'?obj.message:obj
  })
}

Vue.prototype.Success = function(param){
  if(typeof param == 'object'){
    let {title,desc,content} = param
    
    this.$Notice.success({title,desc:content || desc})
  }else{
    
    this.$Notice.success({title:"操作完成",desc:param})
  }
}

Vue.prototype.RouteBack = function(){
  window.history.go(-1)
}

Vue.prototype.RouteTo = function (path, newtab = false) {
  if (window && window.event && window.event.ctrlKey)
    newtab = true
  if (!path)
    return

  // route to given url
  if (newtab) {
    if (!path.includes('http')) {
      let routerUrl = this.$router.resolve({
        path
      });
      window.open(routerUrl.href, "_blank");
    } else {
      window.open(path, "_blank");
    }
  } else {
    if (!path.includes('http')) {

      if (this.$route.path != path)
        this.$router.push(path)
    } else {
      window.location.href = path
    }
  }
}


import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
// Automatically loads and bootstraps files
// in the "./src/components/base" folder.
const requireComponent = require.context('@/components/base', true, /\.vue$/)

for (const file of requireComponent.keys()) {
  const componentConfig = requireComponent(file)
  const name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
  const componentName = upperFirst(camelCase(name))
  Vue.component(`Base${componentName}`, componentConfig.default || componentConfig)
}

const requireComponentPanel = require.context('@/components/panel', true, /\.vue$/)

for (const file of requireComponentPanel.keys()) {
  const componentConfig = requireComponentPanel(file)
 
  const name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
  const componentName = upperFirst(camelCase(name))
  Vue.component(`Panel${componentName}`, componentConfig.default || componentConfig)
}

const requireComponentFormControls = require.context('@/components/form/controls', true, /\.vue$/)
for (const file of requireComponentFormControls.keys()) {
  const componentConfig = requireComponentFormControls(file)
  const name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
  const componentName = upperFirst(camelCase(name))
  
  Vue.component(`Ugo${componentName}`, componentConfig.default || componentConfig)
}


function getBlob(url, cb) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.send();
}

/**
 * 保存
 * @param  {Blob} blob
 * @param  {String} filename 想要保存的文件名称
 */
function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    var body = document.querySelector("body");

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = "none";
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
}

/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
function download(url, filename) {
  getBlob(url, function (blob) {
    saveAs(blob, filename);
  });
}

Vue.prototype.SaveAs = saveAs
Vue.prototype.DownloadWithName = download

Vue.directive('transfer', {
  inserted: el => {
    el.parentElement.removeChild(el)
    document.body.appendChild(el)

  }
})



Vue.config.devtools = true
Vue.config.productionTip = false


//Vue.config.productionTip = false

export default Vue
