<template>
    <Modal 
      :value="value"
      footer-hide
      icon="md-document"
      :title="(data.id?'编辑':'新建') +' - ' + this.formDef.name"
      @input="$emit('input',$event)"
      :width="width"
      >  
      <BaseLoading v-if="loading_model">加载中</BaseLoading>
      <UgoForm 
        ref="form" 
        v-else 
        :form="formDef"
        v-model="formData"
        :old="data"
        @on-submit="handleSubmit"
        />
      <div class="flex-wrap flex-right">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="$emit('input',false)" style="margin-left:10px">取消</Button>
      </div>
    </Modal>
  </template>
  
  <script>
  import UTIL from '@/utils'
  import {cloneDeep} from "lodash"
  import UgoForm from '@/components/form'
  export default {
    components:{
        UgoForm},
      props:{
          value:{
              type:Boolean
          },
          data:{
              type:Object,
              default:()=>({})
          },
          model:{
            type:String,
            default:""
          },
          width:{
            type:Number,
            default:600
          },
          loading:{
              type:Boolean,
              default:false
          }
      },
      watch:{
        model:{
            handler(v){
                if(v){
                    this.getData()
                }
            },
            deeply:true,
            immediate:true
        },
          data:{
              handler(v){
                  if(this.data && this.data.id)
                       this.formData = cloneDeep(this.data)
                  else
                      this.formData = this.initFormData()
              },
              deep:true
          }
      },  
      mounted(){
           
      }, 
      data(){
          return {
                flow:{},
                formData:{},
                deps:[],
                submitting:false,
                loading_model:false,
                updated:{},
                formDef:{
                    name:'',
                    fields:[],
                    config:{}
                },
          }
      },
      methods:{
        
          getData(){
            this.loading_model = true
            this.$api.afterLogin(()=>{
                this.$api.get(`models/${this.model}?view=${this.view}`).then(res=>{
                
                this.formDef = res.data.data
                if(this.data && this.data.id)
                  this.formData = cloneDeep(this.data)
              else
                  this.formData = this.initFormData()
            }).catch(e=>{
                this.Error(e)
            }).finally(()=>{
                this.loading_model = false
            })   
            })
              
          },
          initFormData(){
              return {}
          },
          stopLoading(){
              this.submitting = false
          },
          submit(){
            this.$refs.form.submit()
          },
          handleSubmit(model){
            this.submitting = true
            if(this.data.id){
                let updateData = this.$refs.form.getUpdateData()
                console.log(updateData)
                if(!updateData){
                    this.handleCancel()
                    return
                }
                
                this.$emit('on-event',{type:'update',value:updateData})
            }else{
                this.$emit('on-event',{type:'create',value:model})
            }
                      
          },
          handleCancel(){
            this.$emit('input',false)
              this.$emit('on-event',{type:'cancel'})
          }
      }
  }
  </script>
  
  <style>
  
  </style>