<template>
  <App>
    <AppMask class="flex-wrap flex-center" style="background:var(--bg2);border-left:1px solid var(--border);overflow:hidden;z-index:1000;color:var(--subtext2);transition:all 0.3s ease-out" v-if="loading" :style="ready?'':'top:120%;'">
      <div class="flex-col">
      <h2 style="font-size:40px">成本合约系统</h2>
      <div class="flex-wrap" style="margin-top:10px">
      <BaseIcon icon="nbgz" color="var(--subtext2)" size="40" />
      <BaseLoading style="margin:0" />
      <BaseIcon icon="md-analytics" color="var(--subtext2)" size="40" />
      </div>
      
      </div>
    </AppMask>
    <AppMask class="flex-wrap flex-center" style="background:var(--bg1);border-left:1px solid var(--border);color:#fff;transition:all 0.3s ease-out;bottom:50%;" v-if="loading" :style="ready?'':'bottom:120%'">
      <div>
      </div>
    </AppMask>
    <AppBar class="flex-between" @click.self="open=false">
      <div class="flex-wrap">
        <AppTitle name="成本合约管理" icon="logo-buffer" />
      </div>
      <AppMenu 
        :menus="menus" 
        :actived-key="selected_key" 
        base-route="/core/cm/"
        :small="sm"
      ></AppMenu>
      <div class="flex-wrap split5">
        <Button type="private" @click="showFlow=true" v-if="flow_todo_count > 0">待我处理 <span :style="`background:${true?'var(--warning);color:var(--hover-text);':'#11111122'};padding:1px 5px;border-radius:5px;margin-left:3px;`">{{ flow_todo_count }}</span> <BaseIcon icon="down" /></Button>
        <Button type="private" v-if="flow_in_count > 0" @click="showFlow=true">在途业务 <span :style="`background:${true?'var(--primary);color:var(--hover-text);':'#11111122'};padding:1px 5px;border-radius:5px;margin-left:3px;`">{{ flow_in_count }}</span> <BaseIcon icon="down" /></Button>
        <Dropdown @on-click="changeRole"  trigger="click" :disabled="permission.roles.length <= 1">
          <div style="border:1px solid var(--border);padding:5px 10px;border-radius:5px;">{{my_role ? my_role.name :'-'}} <BaseIcon icon="down" /></div>
          <DropdownMenu slot="list">
            <DropdownItem class="flex-wrap flex-center" v-for="r in permission.roles" :key="r.id" style="padding:5px;border-bottom:1px dashed var(--border)" :name="r.id">
              {{r.name}} <BaseIcon icon="md-checkmark" style="margin-left:5px;cursor:pointer;" color="var(--primary)" v-if="r.id == my_role.id" />
            </DropdownItem>
          </DropdownMenu> 
        </Dropdown>
        <Button icon="md-speedometer" @click="RouteTo('https://b.inbgz.com?key=nbgz')">前往数字看板</Button>
      </div>
    </AppBar>
    <AppClient>
      <Drawer v-model="showFlow" width="600" inner title="在途业务" :styles="{'padding':0}">
        <div class="container" style="background:var(--bg2)">
          
        工作流
        </div>
      </Drawer>
      <keep-alive>
        <router-view ref='sub' v-if="!loading_permit"></router-view>
      </keep-alive>
    </AppClient>
    <Drawer v-model="showUserInfo" :width="800">
      <div class="container" style="overflow-y:auto;height:100%;">
        <div style="padding:10px">
            <div style="font-size:20px;color:var(--primary);font-weight:bold;">
              
            {{ currentUser.name }} (模块开发中)
            
            </div>
            <div style="margin-top:5px">
              {{ currentUser.top_dep_name }} <span v-if="currentUser.down_dep">/ {{ currentUser.down_dep_name }}</span> <span style="color:var(--subtext2);margin-left:10px">(部门数据来源于钉钉)</span>
            </div>
          </div>
        <Card style="margin:5px">
        
          <BaseBoardSubTitle name="基本信息" />
          
          <Row>
            <Col :span="19">
            <Row>
              <Col :span="8">
              <div class="info flex-wrap">
                <div>性别</div>
                <div>男</div>
              </div>
               </Col>
               <Col :span="8">
              <div class="info flex-wrap">
                <div>年龄</div>
                <div>41岁</div>
              </div>
               </Col>
               <Col :span="8">
              <div class="info flex-wrap">
                <div>生日</div>
                <div>1974/03/12</div>
              </div>
               </Col>
               </Row>
               <Row style="margin-top:10px">
              <Col :span="8">
              <div class="info flex-wrap">
                <div>籍贯</div>
                <div>浙江</div>
              </div>
               </Col>
               </Row>
               
               <Row style="margin-top:10px">
               <Col :span="8" >
              
              <div class="info flex-wrap">
                <div>毕业时间</div>
                <div>{{ moment().format("YYYY-MM") }}</div>
              </div>
               </Col>
               <Col :span="16" >
              
              <div class="info flex-wrap">
                <div>毕业院校</div>
                <div>{{ moment().format("YYYY-MM") }}</div>
              </div>
               </Col>
              </Row>
              <Row style="margin-top:10px">
                <Col :span="8" >
              
              <div class="info flex-wrap">
                <div>专业</div>
                <div>土木工程</div>
              </div>
               </Col>
               <Col :span="8" >
              
              <div class="info flex-wrap">
                <div>学历</div>
                <div>本科</div>
              </div>
               </Col>
               <Col :span="8" >
              
              <div class="info flex-wrap">
                <div>学位</div>
                <div>学士学位</div>
              </div>
               </Col>
              </Row>
              <Row  style="margin-top:10px">
                <Col :span="8" >
              <div class="info flex-wrap">
                <div>政治面貌</div>
                <div>党员</div>
              </div>
               </Col>
               <Col :span="8" >
              <div class="info flex-wrap">
                <div>入党时间</div>
                <div>1983/03/12</div>
              </div>
               </Col>
               <Col :span="8" >
              <div class="info flex-wrap">
                <div>党龄</div>
                <div>30年</div>
              </div>
               </Col>
               </Row>
               <Row style="margin-top:10px">
               <Col :span="8" >
              
              <div class="info flex-wrap">
                <div>入职时间</div>
                <div>{{ moment().format("YYYY-MM") }}</div>
              </div>
               </Col>
              </Row>
              <Row style="margin-top:10px">
               <Col :span="24" >
              <div class="info flex-wrap">
                <div>个人签名</div>
                <div>无</div>
              </div>
               </Col>
            </Row>
            </Col>
            <Col :span="4"  :push="1">
              <img :src="currentUser.photo || 'https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/icon/employee.png'" style="width:100%;aspect-ratio:0.8" />
            </Col>
            
          </Row>
        </Card>
        <Card style="margin:5px">
          <BaseBoardSubTitle name="联系方式" />
          <Row style="margin-top:10px">
              <Col :span="8">
              <div class="info flex-wrap">
                <div>电话</div>
                <div>138-8888-8888</div>
              </div>
               </Col>
               <Col :span="8">
              <div class="info flex-wrap">
                <div>QQ</div>
                <div>4231442333</div>
              </div>
               </Col>
               </Row>
        </Card>
        
        <Card style="margin:5px">
          <BaseBoardSubTitle :name="`项目(${currentUser.projects?currentUser.projects.length:0})`" />
          <div>
            <template v-for="(p,i) in currentUser.projects">
              <Row :key="p.id">
                <Col :span="1">{{ i+1 }}</Col>
                <Col :span="15">{{ p.name }}</Col>
                <Col :span="3">{{ p.status }}</Col>
                <Col :span="3">{{ p.position }}</Col>
                <Col :span="2" align="right">{{ p.avg_factor }}</Col>
              </Row>
            </template>
          </div>
        </Card>
        <Card style="margin:5px">
          <BaseBoardSubTitle name="工作计划" />
          <template v-if="currentUser.memos">
            <div style="margin:10px;">进行中({{ currentUser.memos.length }})</div>
            <template v-for="(p,i) in currentUser.memos">
              <Row :key="p.id">
                <Col :span="1">{{ i+1 }}</Col>
                <Col :span="15">{{ p.name }}</Col>
              </Row>
            </template>
          </template>
        </Card>
        <Card style="margin:5px">
          <BaseBoardSubTitle name="工作安排" />
          
            <template v-if="currentUser.tasks">
            <div style="margin:10px;">进行中({{ currentUser.tasks.length }})</div>
            <template v-for="(p,i) in currentUser.tasks">
              <Row :key="p.id">
                <Col :span="1">{{ i+1 }}</Col>
                <Col :span="15">{{ p.name }}</Col>
              </Row>
            </template>
          </template>
          <template v-if="currentUser.finsihed_tasks">
            <div style="margin:10px;">已完成({{ currentUser.finsihed_tasks.length }})</div>
            <template v-for="(p,i) in currentUser.finsihed_tasks">
              <Row :key="p.id">
                <Col :span="1">{{ i+1 }}</Col>
                <Col :span="15">{{ p.name }}</Col>
              </Row>
            </template>
          </template>
        </Card>
        <Card style="margin:5px">
          <BaseBoardSubTitle name="证书" />
        </Card>
        <Card style="margin:5px">
          <BaseBoardSubTitle name="考核" />
        </Card>
        <Card style="margin:5px">
          <BaseBoardSubTitle name="表彰" />
        </Card>
        <Card style="margin:5px">
          <BaseBoardSubTitle name="培训" />
        </Card>
      </div>
    </Drawer>
  </App>
</template>

<script>
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppTitle from '@/components/app/title'
import AppMenu from '@/components/app/menu'
import AppClient from '@/components/app/client'
import AppMask from '@/components/app/mask'

import responsableMixin from '@/mixins/responsable'
import { mapGetters } from 'vuex'
export default {
  components: { App, AppBar, AppTitle, AppMenu, AppClient,AppMask },
  routerOption: {
    as_frame: true
  },
  data() {
    return {
      open: false,
      selected_key:"",
      showFlow:false,
      ready:false,
      loading:false,
      loadingUserDetail:false,
      currentUser:{},
      showUserInfo:false,
    }
  },
  watch:{
    $route:{
      handler(v){
        let path = this.$route.path
      let cmIndex = path.indexOf('/cm/') + 4
      let lastSlashIndex = path.lastIndexOf('/')

      this.selected_key = path.slice(cmIndex, lastSlashIndex <= cmIndex ? undefined : (lastSlashIndex))
      },
      immediate:true,
      deep:true
    }
  },
  
  mixins:[responsableMixin],
  computed: {
    ...mapGetters('cm', ['permission','my_role','projects', 'filtered_projects', 'deps', 'mode', 'dep', 'started_at', 'finished_at', 'timeValue', 'timeValueFormatted', 'loading_permit','flows','flow_todo_count','flow_in_count']),
    sm(){
      return this.w < 800
    },
    menus() {
      const menus = [{
        name: '工作台',
        icon: "desktop",
        key: 'dashboard'
      },
      
      {
        name: '项目库',
        icon: "xiangmu",
        key: 'projects'
      }, 
      {
        name:'工作流',
        icon:"lianjieliu",
        key:'flows'
      },
      // {
      //   name: '数据导出',
      //   icon: 'file',
      //   key: 'report'
      // }, {
      //   name: '数字大屏',
      //   icon: 'chart',
      //   key: 'boards'
      // }
    ]

      if (this.permission && Array.isArray(this.permission.permissions)) {

        return [...menus.slice(0, 1), ...menus.slice(1).filter(v => this.permission.permissions.find(p => ((p.target_id == v.key || p.target_id == 'all') && p.target_type == 'cm-mod') || (p.target_type == 'all')))]
      } else {
        return menus
      }

    }
  },
  methods:{
    changeRole(r){
      this.loading = true
      setTimeout(()=>{
        this.loading = false
      },1000)
      this.$store.commit('cm/change_role',r)
    },
    handleOpenUser(user){
      this.showUserInfo = true
      this.currentUser = {...user} 
      this.loadingUserDetail = true
      this.$api.get('/cm/employees/'+user.id).then(res=>{
        Object.assign(this.currentUser,res.data.data)
      }).finally(()=>{
        this.loadingUserDetail = false
      })
    },
  },
  mounted() {
    this.$store.dispatch('')
    this.$bus.$on('show-cm-user',(data)=>{
      this.handleOpenUser(data)
    })
    this.loading = true
    
      this.ready = true
    setTimeout(()=>{
      this.ready = false
      setTimeout(()=>{
        //this.loading = false
      },1000)
    },1000)
  },
}
</script>

<style lang="less">
.cm-button {
  padding: 0 5px;
  border: 1px solid #aaa;
  border-radius: 40px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.cm-button:hover {
  background: #eee;
  cursor: pointer;
}
</style>