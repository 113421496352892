<style lang="less">
.ugo-form{
  .ivu-form-item{
    width:100%;
  }
  
  .ivu-form-item-label{
    color:var(--subtext2);
  }

  .ivu-form-item-content{
  }
}
</style>

<template>
<Layout style="overflow:hidden">
  <div style="height:50px;padding:0 !important;position:relative !important;border-bottom:1px solid var(--border);">
    
    <Toolbar 
      :items="tools"
      :height="48"
      @on-event="handleToolEvent"
    />
  </div>

  <Layout style="height:calc(100% - 100px)">
    <Sider style="width:200px;background:#fff;border-right:1px solid #aaa;">
      <div class="l-panel-title">内容</div>
      <div style="height:180px;overflow:hidden;position:relative;overflow-y:auto;">
      <BaseTree 
        ref="tree"
        :selected="selected" manu 
        @event="handleTreeEvent"
        subname="fields"
        :data="computedForm"  />
    </div>
      <div style="position:absolute;bottom:24px;top:200px;left:0;right:0;">
        <div class="l-panel-title">组件</div>
        <div class="flex-wrap" style="height:100%;overflow-y:auto;align-items:flex-start;">
          <draggable :list="controls" :group="{ name: 'control', pull: 'clone', put: false }" :clone="handleClone" style="width:100%">
            <transition-group type="transition" name="flip-list">
              <div class="u-control-item" :key="c.control" v-for="c in controls">
                <Icon :custom="`ugoicon ugi${c.icon || 'input'}`" size="18" style="margin-right:5px" /> {{c.name || c.control}}
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </Sider>
    <Content style="background:#ddd;overflow-y:auto;height:calc(100%);">
     
      <div style="width:900px;margin:60px auto;background:#fff;position:relative;">
        <template v-if="form.html">
          <a style='font-size:14px;color:#3af;position:absolute;top:-25px;' @click="view=(view=='html'?'form':'html')">切换至 {{view=='html'?'原始表单':'HTML表单'}}</a>
        </template>
         <div class="" style="overflow:hidden;height:auto;margin:20px;background:none !important;padding:40px 0;">
      <Form 
        :label-width="form.config.labelWidth || 100" 
        class="ugo-form" 
        :label-position="form.config.labelPosition || 'left'"
      >
        
        <template v-if="view == 'html'">
          
          <BaseForm :form="{layout:form.html,def:formDef}"
          
          v-model="formData" />
        </template>
        <template v-else>
         <draggable 
          v-model="form.fields"
          @input="save"
          @end="handleDragEnd"
          group="control"
          style="width:100%;min-height:150px;background:#eee;border:1px dashed #39f;padding:5px 0;display: block;"
          :animation="500"
          filter=".drag-tip"
         >
          
          <!-- <BaseEmpty v-if="!form.fields || form.fields.length == 0" msg="拖拽组件到此处" /> -->
         
          <template v-for="(p) in form.fields">
            <div class="form-item flex-wrap flex-between" :class="selected==p?'form-item-selected':''"  :key="p.id" 
            @click.stop="select(p)" style="border:1px solid var(--primary);margin:12px;position:relative;overflow:hidden;padding:5px;">
                <div class="l-mask" v-if="!isContainer(p.control)"></div>
                <div class="l-remove-btn" @click.stop="handleRemove(p)"><Icon type="md-close" /></div>
                <keep-alive>
                  <component 
                    :is="p.control" 
                    :required="p.required?true:false"
                    :label="p.name"
                    :id="p.id"
                    :fkey="p.key" 
                    :selected="selected"
                    :fields="p.fields"
                    :config="p.config" 
                    :editable="true"
                    :design="true" 
                    @select="handleSelect"
                    @change="handleChangeFields(p,$event)"
                  
                  />
                </keep-alive>
            </div>
          </template>
          
          <div v-if="form.fields && form.fields.length != 0" class="drag-tip">拖拽组件到此处</div>
         
         </draggable>
         
         </template>
      </Form>
    </div>
      </div>
    </Content>
    <Sider style="width:240px;background:#fff;border-left:1px solid #aaa;">
      <div class="l-panel-title">属性</div>
      <BaseEmpty v-if="!selected || selected.name==undefined" msg="请选择组件" />
      <template v-else>
        <BaseProperty 
          v-model="selected"
          :attrs="selected != form?fieldConfig:formConfig"
          :disbaled="{control:true}"
          @input="handleChange" />
        <BaseProperty 
          v-if="selected"
          v-model="selected.config"
          :attrs="getAttrConfig(selected)"
          @input="handleChange" 
        />
      </template>
    </Sider>
  </Layout>
  <div class="flex-wrap" style="align-items:stretch;height:24px;border-top:1px solid #aaa;background:#eee;padding:0 !important;font-size:12px;z-index:100;">
      <div class="flex-wrap flex-center" style="padding:0 10px;border-right:1px solid #aaa;">编辑中</div>
      <div class="flex-wrap flex-center" style="padding:0 10px;border-right:1px solid #aaa;width:calc(100% - 160px);"> 
        {{selecting_to?'请选择下一节点':''}}</div>
      
    </div>

    <Modal v-model="openFolder" title="选择现有表单" footer-hide>
      <div>
        <div class="flex-wrap">
          <Input size="small" />
        </div>
        <div  style="flex-wrap:wrap;height:400px;overflow-y:auto;margin-top:10px;padding:5px;border:1px solid var(--border);">
           <template v-for="f in forms">
            <div class="flex-wrap flex-between" :key="f.id">
              <div style="color:var(--primary);cursor:pointer;" @click="insertForm(f)">{{ f.name }}</div>
              <div>{{ moment(f.created_at).format("LL") }}</div>
            </div>
            </template> 
        </div>
      </div>
    </Modal>
  </Layout>
</template>

<script>
import {upperFirst,camelCase,cloneDeep} from 'lodash'
import draggable from 'vuedraggable'
import Toolbar from '@/components/app/toolbar'
import UgoXForm from './index.vue'



  const requireComponentFormControls = require.context('@/components/form/controls', true, /\.vue$/)
let Controls = []
for (const file of requireComponentFormControls.keys()) {
  const componentConfig = requireComponentFormControls(file)
  const name = file
    .replace(/index.js/, '')
    .replace(/^\.\//, '')
    .replace(/\.\w+$/, '')
  const componentName = upperFirst(camelCase(name))
  
  Controls.push({
    control:'Ugo'+componentName,
    name:componentConfig.default.cname,
    corder:componentConfig.default.corder,
    icon:componentConfig.default.icon,
    properties:componentConfig.default.properties || [],
    initModel:componentConfig.default.initModel
  })
}


let idGlobal =1
  export default {
    props:['value','public'],
    data(){
      return {
        selecting_to:null,
        view:'form',
        selected:{},
        fields:[],
        form:{},
        forms:[],
        drag:false,
        openFolder:false,
        fieldConfig:[{
          label:"名称",
          key:"name",
          control:"Text",
          editable:true
        },{
          label:"数据名",
          key:"key",
          control:"Text",
          editable:true,
          option:{
            
            placeholder:"默认为名称"
          }
        },{
          label:"组件",
          key:"control",
          control:"Text"
        },{
          label:"必填",
          key:"required",
          editbale:true,
          control:"Check"
        }],
         formConfig:[{
            label:"表单名称",
            key:"name",
            control:"Text"
          },{
            label:"表单类型",
            key:"type",
            control:"Select",
            option:{
              options:["标准表单","临时表单","内嵌表单"]
            }
          },{
            label:"表单简介",
            key:"description",
            control:"Text"
          },{
            label:"HTML表单",
            key:"html",
            control:"Text"
          },{
            label:"绑定对象",
            key:"model",
            control:"Select",
            option:{
              valueKey:'key',
              getters:"session/models"
            }
          },],
      }
    },
    computed:{
      computedForm(){
        return [this.form]
      },
      nodes(){
        return [this.form]
      },
       tools() {
        if(this.public){
          return [{}]
        }
        return [{
          key: "open",
          name: "打开",
          icon: 'ios-folder-outline',
          type:"icon-button"
        }]
       },
       controls(){
        Controls.sort((a,b)=>(a.corder==undefined || a.corder>b.corder)?1:-1)
        console.log(Controls.map(v=>v.name+','+v.corder))
        return Controls
      },
      formDef(){
        let o = {}
        if(this.form.fields){
          
        this.form.fields.forEach(v=>{
          if(v.key || v.name)
            o[v.key || v.name] = {
              label:v.name,
              control:'input',
              option:{}
            }
        })
        }
        return o
      }
    },
    mounted(){
      if(this.value){
        this.form = cloneDeep(this.value)
      }else{
        this.form = {
          id:'create',
          name:"新建表单",
          type:"临时表单",
          config:{},
          fields:[]
        }
        
      }
      this.$nextTick(()=>{
        this.selected = this.form
        if(this.$refs.tree)
          this.$refs.tree.select(this.form)
      })
    
    },
    watch:{
      value:{
        handler(v,old){
          if(v.id !== this.form.id){
          this.form =cloneDeep(this.value)
          if(!this.selected)
            this.selected = this.form
          }
        },
        immediate:true,
        deeply:true
      }
    },
    methods:{
      handleEvent({type,key,value,data}){
      const eventMap = {
        // open:this.openFormSelect,
        // search:this.getData,
        // create:this.createItem,
        // refresh:()=>{
        //   this.getStatusCounts()
        //   this.getData()

        // },
       
      }


      let eventHandler = eventMap[type]
      if(eventHandler){
        eventHandler(value)
      }
    },
    traversalFeilds(fields,cb){
      if(Array.isArray(fields) && cb){
        fields.forEach(f=>{
          cb(f)
          this.traversalFeilds(f.fields,cb)
        })
      }
    },
    insertForm(f){
      let selected = this.selected
      if(!selected)
        selected = this.form
      this.$api.get(`forms/${f.id}`).then(res=>{
       
        let {fields} = res.data.data
        console.log(fields)
        let map = {}
        this.traversalFeilds(fields,(f)=>{
          if(!map[f.id]){
            map[f.id] = 'field-'+f.id
          }
        })
        this.traversalFeilds(fields,(f)=>{
          delete f.form_id
          if(f.id && map[f.id])
            f.id = map[f.id]
          delete f.parent_id
        })

        this.$set(selected,'fields',[...selected.fields,...fields])
        this.openFolder = false
        this.$emit('input',cloneDeep(this.form))
      })
    },
    openFormSelect(){
      this.$api.get('forms').then(res=>{
        this.forms = res.data.data
        this.openFolder = true
      })
      
    },
    select(item){
      if(this.$refs.tree)
        this.selected = this.$refs.tree.select(item)
      else
        this.selected = item
    },
    isContainer(control){
      return this.$store.getters['forms/containers'].includes(control)
    },
    createID(){
      let time = Date.now()
      return time.toString(16) + idGlobal++
    },
      handleClone({name,control}){
        let id = this.createID()
        let obj = {
          id:'field-'+id,
          key:'field-'+id,
          name:`${name}${idGlobal}`,
          control
        }
        let controlDef = this.controls.find(v=>v.control == control)
        if(controlDef && controlDef.initModel)
          controlDef.initModel(obj)
        this.select(obj)
        
        return obj
      },
      log(e){
        //this.handleChange()
        
      },
      handleSelect(e){
        let {item,ctx} = e
        if(item){
          this.select(item)
          this.selected_ctx = ctx
        }
      },
      
      save(){
        this.$emit('input',cloneDeep(this.form))
      },
      handleChangeFields(p,fields){
        this.$set(p,'fields',fields)
        this.save()
      },
      handleChange(){
        if(this.timer)
          clearTimeout()
        this.timer = setTimeout(()=>{
          
          this.save()
        },300)
      },
      getAttrConfig(e){
        let control = this.controls.find(v=>v.control == e.control)
        if(control){
          const defaultConfigProperties = [{
            key:'disabled',
            label:'禁用',
            control:"Check"
          }]
          return [...defaultConfigProperties,...(control.properties || [])]
        }
        else if(e == this.form){
          return [{
            label:"标签宽度",
            key:"labelWidth",
            control:"Text",
            option:{
              number:true,
              unit:'像素'
            }
          },{
            label:"标签位置",
            key:'labelPosition',
            control:'Select',
            option:{
              options:[{
                value:'left',
                label:"靠左"
              },{
                value:'top',
                label:"上方"
              },{
                value:'right',
                label:'靠右'
              }]
            }
          }]
        }
        else if(e.control == 'UgoCol'){
          return [{
            label:"占比",
            help:'可填范围0-24,总占比超出24会自动换行',
            control:"Text",
            key:'span',
            option:{
              number:true
            }
          },{
            label:'对齐',
            key:'align',
            control:"Select",
            option:{
              options:[{
                label:"靠左",
                value:"flex-start"
              },{
                label:"靠右",
                value:'flex-end'
              },{
                label:"居中",
                value:"center"
              }]
            }
          }]
        }else{
          return []
        }
       
      },
      handleRemove(e){
        this.form.fields.splice(this.form.fields.findIndex(v=>v == e),1)
        this.save()
      },
      handleTreeEvent(e){
        if(e && e.type=='select')
          this.selected = e.data
      },
      handleToolEvent(e){
        let {key} = e
        if(key == 'open'){
          this.openFormSelect()
        }
        if(key=='clear'){
          this.form.fields = []
          this.handleChange()
        }
      },
      handleDragEnd(e){
        return false
      }
    },
    components:{UgoXForm,draggable,Toolbar}
  }
</script>

<style lang="less">
.u-control-item{
  width:calc(100% - 10px);
  height:35px;
  display: flex;
  align-items: center;
  border:1px solid #ddd;
  padding:0 5px;
  margin:2.5px 5px;
  overflow: hidden;
  font-size:12px;

}

.u-control-item:hover{
  background:#3399ff33;
  cursor: pointer;
  transition: 0.5s;
}
.l-mask{
  position: absolute;
  z-index:999999;
  left:0;
  top:0;
  bottom:0;
  right:0;
  opacity: 0.1;
}
.l-mask:hover{
  opacity:0.3;
  transition:opacity 0.5s;
}
.l-remove-btn{
   position: absolute;
  z-index:1000000;
 
  top:0;
  width:20px;
  height:20px;
  right:0;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.l-remove-btn:hover{
  i{
  color:var(--primary) !important;
  }
}

.form-item{
  position: relative;
  .l-mask{
    background:var(--primary);
  }
}

.form-item-selected{
  border-color:var(--warning) !important;

  .l-mask{
    background-color:var(--warning);
  }
  
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.drag-tip{
  display: flex;
  align-items: center;justify-content: center;
  margin:5px;
  color:#aaa;
}

.l-panel-title{
height:25px;padding:4px 5px;background:#eee;font-size:10px;
border-bottom:1px solid #aaa;
}
</style>