<template>
  <div class="flex-wrap flex-between">
      <div  style="width:calc(100% - 300px);height:100%;overflow-y:auto;background:#eee;">
        <UgoXForm 
          ref="testform"
          :form="_form"
          style="padding:20px;margin:20px auto;width:800px;min-height:200px;" v-model="formData"
          @on-submit="handleSubmit"
          editable
  
        />
       
      </div>
      <div  style="align-items:flex-start;width:400px;height:100%;padding:10px;">
        <div>表单的数据</div>
        <pre style="background:#000;color:#aaa;padding:10px;max-height:300px;overflow-y:auto;">{{form}}</pre>
        <div class="flex-wrap flex-center" style="margin-top:10px">
          <Button @click="submit" type="primary">提取数据</Button>
        </div>
        <div>您提交的数据</div>
        <pre style="background:#000;color:#aaa;padding:10px;">{{formData}}</pre>
      </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import UgoXForm from './index.vue'
  export default {
    components:{UgoXForm},
    props:['form'],
    watch:{
      form:{
        handler(v){
          this._form = cloneDeep(v)
          this.formData = {}
           this.$forceUpdate()
        },
        deep:true,
        immediate:true
      }
    },
    data(){
      return {
        formData:{},
        _form:{}
      }
    },
    methods:{
      submit(){
        this.$refs.testform.submit()
      },
      handleSubmit(e){
        console.log(e)
        this.formData=e
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>