<template>
  <Container>
    <div class="flex-wrap flex-between" style="padding:0 10px">
    <Toolbar 
      :items="tools"
      v-model="filter"
      :counts="counts"
      @on-event="handleEvent"
    >
      
    </Toolbar>
    <div class="flex-wrap">
    <Button style="margin-right:5px" icon="md-add" type="primary" @click="showCreateForm">新建项目</Button>
    <Button style="margin-left:5px" icon="md-download" @click="exportExcel" /> 
    </div>
  </div>
    <div style="height:70px;border-bottom:1px solid var(--border);padding:0 10px">
      <Toolbar 
        :items="filterTool"
        v-model="filter"
        :counts="counts"
        :height="60"
        @on-event="handleEvent"
      />
    </div>
    
    <div class="table-wrap" style="flex-grow:1;position;relative">
      <BaseTable 
        ref="table"
        :columns="columns"
        :data="items"
        :page="page"
        :page-size="pagesize"
        border
        @event="handleEvent"
        :loading="loading"
        :count="total"
      ></BaseTable>
    </div>

    <Editor
      ref="editor" 
      v-model="showEditor"
      :data="current"
      :loading="loadingEditor"
      :width="900"
      model="BU_Bid_Project"
      @on-event="handleEvent"
    />

    <!-- <Editor
      ref="flow_editor"
      v-model="showFlowEditor"
      :data="current"
      model="BFlowInstance"
      @on-event="handleFlowEditorEvent"
      /> -->
  </Container>
</template>

<script>
import Container from '@/components/app/container'
import Toolbar from '@/components/app/toolbar'
import Editor from '@/components/model/editor'
import moment from 'moment'
import {cloneDeep} from 'lodash'
export default {
  routerOption: {
    as_default: true
  },
  components:{Container,Toolbar,Editor},
  data(){
    return {
      filter:{
      },
      showEditor:false,
      loadingEditor:false,
      showFlowEditor:false,
      flowFormData:{},
      items:[],
      loading:false,
      current:{},
      formData:{},
      page:1,
      pagesize:20,
      total:10,
      counts:{
      },
      order:{
        key:'created_by',
        order:'desc'
      },
      deps:[]
    }
  },
  methods:{
    handleFlowEditorEvent(e){

    },
    showCreateForm(){
      this.current = {}
      this.formData = {}
      this.showEditor = true
    },
    Edit(item){
      this.loadingEditor = true
      this.showEditor = true
      this.$api.get(`business/bid_projects/${item.id}?edit=1`).then(res=>{
        this.current = res.data.data
        this.loadingEditor = false
        this.formData = cloneDeep(this.current)
        this.showEditor = true
      }).catch(e=>{
        this.loadingEditor = false
        this.loadingEditorError = true
      })
     
    },
    exportExcel(){
      this.$refs.table.exportExcel()
    },
    getStatusCounts(){
      this.$api.getCounts("/business/bid_projects",{
        count_by:'status'
      }).then(res=>{
        this.counts = Object.assign({},this.counts,res.data.data)
      })
    },
    getData(){
      this.loading = true
      this.$api.afterLogin(()=>{
        this.$api.getPaged("/business/bid_projects",{
        page:this.page,
        pagesize:this.pagesize,
        order:this.order,
        filter:this.filter,
        related:['services','building_categories','project_info','bid_info']
      }).then(res=>{
      
        let {count,list} = res.data.data
        list.forEach(l=>{
          if(l.project_info){
          Object.assign(l,l.project_info)
        }
        if(l.bid_info){
          Object.assign(l,l.bid_info)
        }
        })
        
        this.items = list
        this.total = count
        
      }).catch((e)=>{
        this.Error(e)
      }).finally(()=>{
        this.loading = false
      })

      this.$api.get("/deps?q=subject").then(res=>{
        this.deps = res.data.data
      })
      })
      
    },
    createItem(model){
      this.$api.post('/business/bid_projects',model).then(res=>{
        this.$Notice.success({title:"创建成功"})
        this.showEditor = false
        this.getData()
      }).finally(()=>{
        this.$refs.editor.stopLoading()
      })
    },
    updateItem(model){
      let id =  this.current.id
      this.$api.patch(`/business/bid_projects/${id}`,model).then(res=>{
        this.$Notice.success({title:"修改成功"})
        this.showEditor = false
        this.getData()
      }).finally(()=>{
        this.$refs.editor.stopLoading()
      })
    },
    handleDelete(item){
      this.Confirm(`即将删除此项目<span style="color:var(--error)">${item.name}</span>，是否继续?`,()=>{
        this.$api.delete("/business/bid_projects/"+item.id).then(res=>{
          this.$Notice.success({title:"删除成功"})
          this.getData()
        })
      })
    },
    createFlow(task){
      this.flowFormData = {
        target_id:task.id,
        
      }
      this.showFlowEditor = true
    },
    CreateNext(item){
      this.Confirm(`将为此项目<span style='color:red;margin:0 3px'>${item.name}</span>创建投标工作, 是否继续?`,()=>{
        this.loading = true
        this.$api.post("/business/bid_projects/create-work",{id:item.id}).then(res=>{
          this.Confirm("投标工作已经创建成功,是否跳转",()=>{
            this.RouteTo('work')
          })
        }).catch(e=>{
          this.Error(e)
        }).finally(()=>{
          this.loading = false
        })
      })
      
    },
    Rollback(item) {
      this.$api.patch("/business/bid_projects/"+item.id,{status:'审核中'}).then(()=>{
        this.Success('撤回成功')
      }).catch(e=>{
        this.Error(e)
      })
    },
    handleEvent({type,key,value,data}){
     
      const eventMap = {
        add:this.showCreateForm,
        search:this.getData,
        create:this.createItem,
        refresh:()=>{
          this.getStatusCounts()
          this.getData()

        },
        update:this.updateItem,
        'tab-change':this.getData,
        'page-change':()=>{
          this.page = data
          this.getData()
        },
        'page-size-change':()=>{
           this.pagesize = data
          this.getData()
        },
        'sort':()=>{
           if(!data || !data.key)
            this.order = {
              key:'created_at',
              order:'desc'
            }
          else
            this.order = data
          this.getData()
        },
        'clearFilter':()=>{
          this.filter={status:this.filter.status}
          this.getData()
        }
      }


      let eventHandler = eventMap[type]
      if(eventHandler){
        eventHandler(value)
      }else{
        eventHandler = eventMap[key]
        if(eventHandler){
          eventHandler(value)
        }
      }
    },
  },
  computed:{
    tools(){
      return [{
        key:'search',
        placeholder:"输入项目名称/招标单位/搜索",
        type:"search",
        width:300
      },{
        key:"status",
        type:"tabs",
        showTotal:true,
        subs:[{
          key:"草稿",
          icon:"file",
          countable:true,
          name:"草稿"
        },{
          key:"审核中",
          icon:"date",
          countable:true,
          name:"审核中"
        },{
          key:"审核通过",
          icon:"pm2",
          countable:true,
          name:"审核通过"
        },{
          key:"已创建",
          icon:"md-checkmark",
          countable:true,
          name:"已创建"
        },{
          key:"未通过",
          icon:"xiangmu2",
          countable:true,
          name:"未通过"
        }]
      },{
        key:'refresh',
        icon:'md-refresh',
        type:"icon-button"
      },{
        key:'clearFilter',
        icon:'md-filter',
        type:"icon-button"
      },]
    },
    filterTool(){
      return [{
        key:"deps",
        type:'tabs',
        name:'归属部门',
        subs:this.deps.map(v=>({...v,key:v.id}))
      },{
        key:"services",
        type:"tabs",
        name:'项目类型',
        subs:[{
                key:1,
                name:"全过程咨询",
                color:'#369',
                icon:'md-apps'
            },{
              key:2,
                name:"项目管理",
                color:'#369',
                icon:'md-apps'
            },{
              key:3,
                name:"市政监理",
                color:'#369',
                icon:'md-apps'
            },{
              key:4,
                name:"房建监理",
                color:'#369',
                icon:'md-apps'
            },{
              key:5,
                name:"其他",
                color:'#369',
                icon:'md-apps'
            }]
      },{
        key:"building_categories",
        type:"tabs",
        name:'建设类型',
        subs:[{
          key:1,
          icon:"file",
          name:"土建"
        },{
          key:2,
          icon:"date",
          name:"学校"
        },{
          key:3,
          icon:"pm2",
          name:"改造"
        },{
          key:0,
          icon:"xiangmu2",
          name:"其他"
        }]
      },{
        key:"bid_eval_method",
        type:"tabs",
        name:'评定方法',
        subs:[{
          key:'综一',
          icon:"file",
          name:"综一"
        },{
          key:'综二',
          icon:"file",
          name:"综二"
        },{
          key:'评定分离:票决',
          icon:"file",
          name:"评定分离:票决"
        },{
          key:'评定分离:价格',
          icon:"file",
          name:"评定分离:价格"
        }]
      }]
    },
    columns(){
      var that = this
      return [
          {type:'index',width:80,title:"序号"},
          {key:'code',width:80,title:"编号"},
          {key:'name',minWidth:240,title:"项目名称",sortable:"custom",
            render:(h,{row})=>{
              let icon = h('BaseIcon' ,{props:{icon:'Building-',size:16,color:'var(--primary)'},style:'margin-left:10px;margin-right:5px;'})
              let tag = h('img',{attrs:{
                 src: "https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/beauty/new.gif"
              },style:'width:30px;height:15px;margin-left:5px;margin-right:10px;'})
              if(moment().subtract(3,'days').isAfter(moment(row.created_by)))
                tag = ''
              let name = h('div',{attrs:{title:row.name},style:"white-space:nowrap;text-overflow:ellipsis;width:calc(100% - 50px;);overflow:hidden;"},row.shortname || row.name)
              return h('div',{
                class:"flex-wrap",
                style:{
                  color:"var(--primary)",
                  fontWeight:'bold',
                  textAlign:"left",
                  cursor:'pointer'
                },
                on:{
                  click:()=>{
                   this.Edit(row)
                  }
                }
              },[icon,name,tag])
            }
          },
          {
            key:'status',width:120,
            type:'flow',
            title:'评审流程',sortable:'custom'
          },
          
          {key:'bid_unit',width:100,title:"招标人",sortable:"custom",
            
          },
          
          {key:'published_at',width:100,title:"发布时间",sortable:"custom",
            render:(h,{row})=>{

              return h('span',moment(row.created_at).format("YYYY-MM-DD"))
          },},
          {key:"published_platform",width:100,title:"发布平台",type:'state',},
          {key:'link',width:120,title:"访问链接",
            render:(h,{row})=>{
              if(row.link){
                return h('a',{attrs:{href:row.link,target:'_blank'}},'点击访问')
              }else{
                return h('span','-')
              }
          },},
          {key:'services',width:120,title:"服务范围",sortable:false,
            render:(h,{row})=>{
              if(!Array.isArray(row.services)){
                return h('span','-')
              }
              let services = row.services.map(v=>v.ref).filter(v=>v)
              
             let limited = services
              if(!limited || limited.length == 0){
                return h('span','-')
              }

              if(limited.length > 3){
                limited = limited.slice(0,2)
              }
              let doms = limited.map(s=>h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--border);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },s && s.name?s.name[0]:''))

              if(services.length > 3){
                let moreDom = h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--bg2);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },`+ ${services.length - 2}`)
                let moreDomTooltip = h('Tooltip',{props:{trigger:"click",content:services.slice(2).map(v=>v.name).join(",")}},[moreDom])
                doms.push(moreDomTooltip)
              }
              return h('div',{class:"flex-wrap flex-center"},doms)
            }
          },
          {key:'building_categories',width:120,title:"工程类型",sortable:false,
            render:(h,{row})=>{
              if(!Array.isArray(row.building_categories))
                return h('span','-')
              let building_categories = row.building_categories.map(v=>v.ref).filter(v=>v)
              
            
              let limited = building_categories

              if(!limited || limited.length == 0){
                return h('span','-')
              }
              if(limited.length > 3){
                limited = limited.slice(0,2)
              }
              let doms = limited.map(s=>h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--border);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },s.name))

              if(building_categories.length > 3){
                let moreDom = h('div',{
                style:"padding:2px 5px;margin-right:1px;flex-shrink:0;background:var(--bg2);border-radius:2px;border:1px solid var(--border);min-width:25px;"
              },`+ ${building_categories.length - 2}`)
                let moreDomTooltip = h('Tooltip',{props:{trigger:"click",content:building_categories.slice(2).map(v=>v.name).join(",")}},[moreDom])
                doms.push(moreDomTooltip)
              }

              return h('div',{class:"flex-wrap flex-center"},doms)
            }
          },
          {key:"controlling_price",width:100,title:"招标控制价",sortable:"custom",
        render:(h,{row})=>{
          return h('div',{class:"flex-wrap flex-right"} ,[
            h("BaseAmount",{props:{value:row.controlling_price,full:true}}),
            h("span",{style:"color:var(--subtext2);margin-left:5px;font-size:12px;margin-right:5px;"},"万元")
          ])
        }},
          {key:"max_price",width:130,title:"报价区间",
        render:(h,{row})=>{
          if(!row.max_price && !row.min_price)
            return h('span','-')
          return h('div',{class:"flex-wrap flex-center"} ,[
            h("span",{style:row.min_price?'color:var(--primary)':''},row.min_price || '待定'),
            h("span",{style:"color:var(--subtext2);margin:0 5px;font-size:12px"},"-"),
            h("span",{style:row.max_price?'color:var(--primary)':''},row.max_price || '待定'),
             h("span",{style:"color:var(--subtext2);margin:0 5px;font-size:12px"},"万元"),
          ])
        }},
          {key:"bid_eval_method",width:120,title:"评标办法",type:'state',sortable:"custom",},
          
          {key:"created_by",type:'user',width:100,title:"录入人",sortable:"custom"},
          {key:"created_at",width:100,title:"录入时间",sortable:"custom",
            render:(h,{row})=>{

              return h('span',moment(row.created_at).fromNow())
          },},
          {key:"tools",width:200,title:"操作",sortable:false,
          fixed:"right",
            render:(h,{row})=>{
              let buttons = [
                h('Button',{props:{size:"small"},on:{click:()=>{that.Edit(row)}}},"修改"),
                h('Button',{props:{size:"small"},on:{click:()=>{that.handleDelete(row)}}},"删除"),
                h('Button',{props:{size:'small',type:'primary'},on:{click:()=>{that.CreateNext(row)}}},'创建工作')
              ]
              return h('div',{class:"flex-wrap flex-center",style:'gap:4px'},[buttons])
            }
          },
        ]
    }
  },
  mounted(){
    this.$api.afterLogin(()=>{
      this.getData()
      this.getStatusCounts()
      this.$nextTick(()=>{
        if (this.$refs && this.$refs.table && this.$refs.table.calcTableHeight)
          this.$refs.table.calcTableHeight()
        
      })
    })
   
  },

}
</script>

<style lange="less">
.filter-box{
  height:50px;
}

.table-wrap{
  height:calc(100% - 140px);
  margin:10px;
  position: relative;
}

</style>