<template>
  <FormItem :label="label">
    {{value}}
    <template v-if="editable">
    <CheckboxGroup 
      :value="value || []"
      @input="handleInput"
      >
      <div class="flex-wrap" style="flex-wrap:wrap;gap:5px 0px">
      <template v-for="o in options">
        <div :key="o.key">
          <Checkbox 
          :label="o.key" :key="o.key" :disabled="!editable"
          :border="config.border">
            <Icon v-if="o.icon" :type="o.icon"></Icon>
            <span>{{o.name}} </span>
        </Checkbox>
        </div>
      </template>
    </div>
    </CheckboxGroup>
    </template>
    <template v-else>
      <div>{{val_objects}}</div>
    </template>
 </FormItem>
</template>

<script>
import composedMixin from '../mixins/composed'
export default {
  cname:"多选框",
  corder:50,
  icon:'-duoxuan',
  properties:[{
    label:"选项",
    key:'options',
    control:"SelectOption"
  },{
    label:"简易选项",
    key:'simple',
    control:"Check"
  },{
    label:'边框',
    key:'border',
    control:'Check'
  },{
    label:'URL',
    key:'url',
    control:'Text'
  }],
  mixins:[composedMixin],
 props:['fkey','config','value','editable','label','old'],
 initModel(e){
  e.config = {
    border:false,
    options:['选项1','选项2'],
    url:''
  }
 },
 data(){
  return {
    items:[]
  }
 },

  computed:{
    val_objects(){
      return Array.isArray(this.value)?
        this.value.map(v=>this.options.find(t=>t.key == v).name).join(','):"-"
    },
    options(){
      let options = this.config.options
      if(Array.isArray(options)){
        if(options && typeof options[0] != 'object')
          options = options.map((v,i)=>({
            key:i,
            name:v
          }))
        
      }
      if(this.config.simple){
        options = options.map(v=>({key:v.name,name:v.name}))
      }
      return options
    }
  },
  methods:{
    handleInput(e){
      this.$emit('input',e) 
    }, 
    
  }
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>